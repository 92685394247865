<template>
  <v-dialog v-model="dialog" persistent max-width="450px">
    <v-card :disabled="loading" :loading="loading">
      <validation-observer ref="form">
        <v-card-title>
          {{ $t("leave.cancels.leave_cancel") }}
          <v-spacer />
          <v-btn depressed icon @click="onClickExit">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="pt-3" v-if="leave">
          <v-flex xs12>
            <validation-provider :name="$t('leave.cancels.message')" rules="required" v-slot="{ errors }">
              <v-text-field
                :label="$t('leave.cancels.message')"
                color="tertiary"
                :counter="512"
                v-model.trim="note"
                :error-messages="errors"
              />
            </validation-provider>
          </v-flex>
        </v-card-text>
        <v-card-actions class="dialog-footer">
          <v-spacer></v-spacer>
          <v-btn color="primary" depressed @click.stop="onClickCancel">{{ $t("buttons.cancel_leave") }}</v-btn>
        </v-card-actions>
      </validation-observer>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    name: "leave-cancel-dialog",
    props: {
      value: Boolean,
      data: Object
    },
    data: () => ({
      dialog: false,
      loading: false,
      leave: null,
      note: null
    }),
    watch: {
      value: {
        handler(status) {
          this.dialog = status;
        },
        immediate: true
      },
      data: {
        handler(leaveRequest) {
          this.leave = leaveRequest;
        },
        deep: true
      }
    },
    methods: {
      onClickExit() {
        this.leave = null;
        this.$emit("close");
      },
      onClickCancel() {
        this.$refs.form.validate().then(valid => {
          if (valid) {
            this.loading = true;
            let abolishReason = { abolishReason: this.note };
            this.$api.leaveRequestService
              .cancel(this.leave.leaveRequest.id, abolishReason)
              .then(data => {
                console.log(data);
                if (!data.error) {
                  this.$emit("success");

                  this.leave = null;
                  this.note = null;
                }
              })
              .catch(e => {
                console.log("e", e);
              })
              .then(() => {
                this.loading = false;
              });
          }
        });
      }
    }
  };
</script>

<style scoped></style>
